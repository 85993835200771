<template>
    <div class="work-card">
        <el-page-header @back="$router.go(-1)" :content="pageHeaderTitle"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <div class="infoBox f-c-js" v-if="baseInfo">
                    <div style="position: relative;">
                        <el-avatar shape="circle" :size="130" fit="fill" :src="warkCardInfo.userAvatarUrl"></el-avatar>
                        <img class="sexImg" v-if="baseInfo[2].value == '男'" src="../../../assets/supply/man.png" alt="">
                        <img class="sexImg" v-if="baseInfo[2].value == '女'" src="../../../assets/supply/woman.png" alt="">
                    </div>

                    <div class="Name_Iphone">
                        <div class="name">{{ baseInfo[1].value }}</div>
                        <div class="iphone">{{ baseInfo[3].value }}</div>
                    </div>
                    <div class="firm">{{ baseInfo[0].value }}</div>
                    <div class="section">{{ baseInfo[4].value }}</div>
                    <div>
                        <div v-if="showModifyEnter" class="flex editBtn" @click="dialogShow = true">
                            <img src="@/assets/supply/edit.png" alt="">
                            <div class="text">编辑</div>
                        </div>
                        <div v-if="showDeleteEnter" class="flex deleteBtn" @click="deleteBtn()">
                            <img src="@/assets/supply/delete.png" alt="">
                            <div class="text">删除</div>
                        </div>
                    </div>
                </div>
                <div class="jurisdiction">
                    <div class="f-c-b">
                        <div class="text">权限信息</div>
                        <div class="btn" @click="ShowjurisdictionList = !ShowjurisdictionList">
                            <span>查看权限</span><i
                                :class="ShowjurisdictionList == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                        </div>
                    </div>
                    <div v-if="ShowjurisdictionList && enterpriseStateInfo.userPermission.a_m != 'D'" class="f-w list">
                        <div>{{ enterpriseStateInfo.a_m }}</div>
                        <div class="item flex" v-for="(i, idx) of powerInfo" :key="idx"
                            :style="{ borderBottom: (idx == 6 || idx == 7) ? 'none' : '' }">
                            <div class="title">{{ i.title }}：</div>
                            <div v-if="i.value" class="value">{{ i.value }}</div>
                        </div>
                        <div class="f1" v-if="enterpriseStateInfo.a_m != 'W'" style="text-align:center;margin: 30px 0;">
                            <el-button type="primary" v-if="showPowerEnter" class="power-btn" @click="drawer = true">修改权限
                            </el-button>
                        </div>
                    </div>
                    <div v-else-if="ShowjurisdictionList && enterpriseStateInfo.userPermission.a_m == 'D'"
                        style="text-align: center;color: red;font-size: 20px;margin: auto;width: 100%;font-weight: bold;">
                        您无权查看</div>
                </div>
            </div>
        </div>
        <!-- <template v-if="!hasData">
            <el-avatar shape="circle" :size="130" fit="fill" :src="warkCardInfo.userAvatarUrl"></el-avatar>
            <div class="f-c-c work-card-btns">
                <el-button type="primary" icon="el-icon-edit" circle class="modify" @click="dialogShow = true"
                    v-if="showModifyEnter">
                </el-button>
                <el-button type="danger" icon="el-icon-delete" circle class="delete" v-if="showDeleteEnter"
                    @click="deleteBtn">
                </el-button>
            </div>
            <div class="info f-c">
                <template v-for="(item, index) of baseInfo">
                    <div class="info-item f-c" :key="index" v-if="item.value">
                        <span>{{ item.title }}：</span>
                        <span class="f1">{{ item.value }}</span>
                    </div>
                </template>
            </div>
            <el-card class="box-card" v-if="showPowerList">
                <el-collapse v-model="activeNames">
                    <el-collapse-item title="权限信息 " name="1">
                        <template v-for="(item, index) of powerInfo">
                            <div class="info-item f-c" :key="index" v-if="item.value">
                                <span>{{ item.title }}：</span>
                                <span class="f1">{{ item.value }}</span>
                            </div>
                        </template>
                        <div class="f1" style="text-align:center;">
                            <el-button type="primary" v-if="showPowerEnter" class="power-btn" @click="drawer = true">修改权限
                            </el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </template> -->
        <!-- <el-empty v-else description="暂无任何信息！"></el-empty> -->
        <!-- <el-dialog title="选择需要搜索的颜色" :visible.sync="dialogShow" width="750px" @close="dialogClose()">
        </el-dialog> -->
        <Dialog title="修改员工信息" width="730px" :visible="dialogShow" @closeDialog="closeDialog">
            <div class="change-name">
                <el-form ref="form" :model="WorkForm" label-width="80px" :rules="workCardRules">
                    <el-form-item v-for="(item, index) of formArr" :label="item.label" :key="index"
                        :required="item.prop == 'name' ? true : false" style="text-align:left">
                        <!-- <div v-if="item.prop == 'name'" class="flex">
                            <span style="color: red;">*</span> -->
                        <ChineseCharactersInput v-if="item.isChineseCharactersInput" :modelValue="WorkForm[item.prop]"
                            :maxlength="'30'" @getValue="getValue" :type="item.prop">
                        </ChineseCharactersInput>
                        <!-- </div> -->

                        <el-radio-group v-if="item.prop == 'sex'" v-model="WorkForm[item.prop]">
                            <el-radio label="男">男</el-radio>
                            <el-radio label="女">女</el-radio>
                        </el-radio-group>
                        <PhoneInput v-if="item.isPhoneInput" :modelValue="WorkForm[item.prop]"
                            :placeholderValue="item.placeholderValue" @getPhoneNumber="getPhoneNumber" :LengthLimit="'16'"
                            @clearPhone="handlePhoneEmpty" :maxlength="16">
                        </PhoneInput>
                    </el-form-item>
                    <el-form-item class="f-c-c">
                        <el-button type="primary" class="modify-ok" @click.prevent="modifyWorkCard('form')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </Dialog>
        <el-drawer title="权限修改" :visible.sync="drawer" :direction="direction" class="drawer-box">
            <el-form :model="drawerForm">
                <el-form-item :prop="i.prop" class="drawer-item f-cl" v-for="(i, idx) of drawerList" :key="idx">
                    <span>{{ i.title }}</span>
                    <el-radio-group v-model="drawerForm[i.prop]">
                        <template v-if="i.prop != 'e_i'">
                            <el-radio v-for="(item, index) of RadioButtonArr" :key="index" :label="item.lable">
                                {{ item.subTitle }}</el-radio>
                        </template>
                        <template v-else>
                            <el-radio v-for="(item, index) of RadioButtonArr1" :key="index" :label="item.lable">
                                {{ item.subTitle }}</el-radio>
                        </template>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="drawer-btns">
                    <el-button type="primary" @click="enterpriseUserRevisePermission">提交修改</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin'
import { workCardRulesNoPhone, workCardRulesHasPhone } from '@/utils/FormRules'
export default {
    inject: ["reload"],
    mixins: [UrlDecodeIdType],
    name: 'Employeeinfo',
    components: { NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue') },
    data() {
        return {
            warkCardInfo: {},
            baseInfo: [],
            powerInfo: [],
            dialogShow: false,
            WorkForm: {
                name: '',
                sex: '',
                phone: '',
                department: ''
            },
            formArr: [{
                label: "姓名",
                prop: "name",
                isChineseCharactersInput: true
            }, {
                label: "性别",
                prop: "sex"
            }, {
                label: "手机号码",
                prop: "phone",
                isPhoneInput: true,
                placeholderValue: '请输入手机号码'
            }, {
                label: "所属部门",
                prop: "department",
                isChineseCharactersInput: true
            }],
            workCardRules: workCardRulesNoPhone,
            showModifyEnter: false,//是否显示修改入口
            showDeleteEnter: false,//是否显示删除入口
            showPowerList: false,//是否显示权限列表
            showPowerEnter: false,//是否显示修改权限入口
            hasData: true,//true-data为空 false-data不为空
            drawer: false,
            direction: 'rtl',
            p_l: '',
            c_m: '',
            e_i: '',
            o_m: '',
            a_m: '',
            f_s: '',
            i_m: '',
            p_m: '',
            drawerForm: {
                "a_m": "",//人员权限管理
                "c_m": "",//客户管理
                "e_i": "",//企业信息
                "f_s": "",//财务统计
                "i_m": "",//库存管理
                "o_m": "",//订单管理
                "p_c": "",//工作牌
                "p_l": "",//产品库
                "p_m": ""//人员管理
            },
            pageHeaderTitle: '',//页头标题
            activeNames: [],//折叠展开
            RadioButtonArr: [{
                lable: 'R',
                subTitle: '查看'
            }, {
                lable: 'W',
                subTitle: '查看/编辑'
            }, {
                lable: 'D',
                subTitle: '不可见'
            }],
            RadioButtonArr1: [{
                lable: 'R',
                subTitle: '查看'
            }, {
                lable: 'W',
                subTitle: '查看/编辑'
            }],
            drawerList: [{
                title: '产品库',
                prop: 'p_l',
            }, {
                title: '客户管理',
                prop: 'c_m',
            }, {
                title: '企业信息',
                prop: 'e_i',
            }, {
                title: '订单管理',
                prop: 'o_m',
            }, {
                title: '人员管理',
                prop: 'p_m',
            }, {
                title: '财务',
                prop: 'f_s',
            }, {
                title: '入库管理',
                prop: 'i_m',
            }, {
                title: '人员权限管理',
                prop: 'a_m',
            }],
            enterpriseStateInfo: {},//权限信息
            ShowjurisdictionList: false,
            isPreviewVisible: false
        }
    },
    created() {
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
        console.log(this.enterpriseStateInfo)
        let that = this;
        if (that.queryType == 'workcard') {//工作牌
            /* 自己看自己的工作牌
            *   1、一定有修改按钮
            *   2、一定可以看到自己的权限列表
            *   3、但是没有权限筛选按钮
            */
            that.pageHeaderTitle = '工作牌'
            that.showModifyEnter = true;
            that.showPowerList = true;
            that.showPowerEnter = false;
            that.userFindNameCard()
        }
        if (that.queryType == 'employeeInfo') {//员工信息
            that.pageHeaderTitle = '员工信息'
            that.enterpriseUserListDetails()
        }

    },
    methods: {
        getPhoneNumber(data) {
            this.WorkForm.phone = data.value
        },
        // 刷新当前页面
        refresh() {
            this.reload();
        },
        getValue(data) {
            this.WorkForm[data.type] = data.value
        },
        // 用户查看工作牌接口
        userFindNameCard() {
            let that = this;
            that.$http.userFindNameCard({
                userId: that.$store.state.userId,
                token: that.$getStorage('token')
            }).then(res => {
                var hasData = (JSON.stringify(res.data) == "{}");
                that.hasData = hasData;
                if (!hasData && res.code == 200) {
                    // a_m 人员权限管理  c_m  客户管理  e_i  企业信息  f_s  财务统计
                    // i_m  库存管理  i_m  订单管理  p_c  工作牌  p_l  产品库  p_m  人员管理
                    let { enterpriseName, ecardName, sex, ecardPhone, ecardNote } = res.data,
                        { a_m, c_m, e_i, f_s, i_m, p_l, p_m, o_m } = res.data.userPermission,
                        baseInfo = [{
                            "title": "企业名称",
                            "value": enterpriseName ?? ''
                        }, {
                            "title": "姓名",
                            "value": ecardName ?? ''
                        }, {
                            "title": "性别",
                            "value": sex ?? ''
                        }, {
                            "title": "手机号码",
                            "value": ecardPhone ?? ''
                        }, {
                            "title": "所属部门",
                            "value": ecardNote ? ecardNote : '未设置'
                        }],
                        powerInfo = [{
                            "title": "产品库",
                            "value": p_l == 'D' ? '不可见' : (p_l == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "客户管理",
                            "value": c_m == 'D' ? '不可见' : (c_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "企业信息",
                            "value": e_i == 'D' ? '不可见' : (e_i == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "订单管理",
                            "value": o_m == 'D' ? '不可见' : (o_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "人员管理",
                            "value": p_m == 'D' ? '不可见' : (p_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "财务统计",
                            "value": f_s == 'D' ? '不可见' : (f_s == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "库存管理",
                            "value": i_m == 'D' ? '不可见' : (i_m == 'R' ? '仅可查看' : '查看/编辑')
                        }, {
                            "title": "权限信息",
                            "value": a_m == 'D' ? '不可见' : (a_m == 'R' ? '仅可查看' : '查看/编辑')
                        }];
                    that.WorkForm = {
                        name: ecardName,
                        sex: sex,
                        phone: ecardPhone,
                        department: ecardNote
                    }
                    that.warkCardInfo = res.data
                    that.baseInfo = baseInfo
                    that.powerInfo = powerInfo
                }
            })
        },
        // 监听手机号清空
        handlePhoneEmpty() {
            this.workCardRules = workCardRulesNoPhone
        },
        // 用户修改工作牌接口
        modifyWorkCard(formName) {
            let that = this;
            if (that.WorkForm.phone) {
                that.workCardRules = workCardRulesHasPhone
            }
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    that.$http.userReviseNameCard({
                        userId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token'),
                        recordeId: that.warkCardInfo.recordeId,
                        ecardName: that.WorkForm.name,
                        ecardPhone: that.WorkForm.phone,
                        ecardNote: that.WorkForm.department,
                        sex: that.WorkForm.sex
                    }).then(res => {
                        if (res.code == 200) {
                            that.$common.message(res.msg, 'success')
                            that.$router.go(-1)
                        }
                    })
                }
            });
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
        // 员工信息-----------------------------
        // 操作者提供权限是否可操作 被操作者提供dcPermissionFlag为N(N代表不是所有者)
        enterpriseUserListDetails() {
            let that = this,
                logerEnterpriseStateInfo = that.$getStorage('enterpriseStateInfo'),//操作者权限
                logerUserId = that.$getStorage('userId');
            that.$http.enterpriseUserListDetails({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                recordeId: parseInt(that.queryId)
            }).then(res => {
                var hasData = (JSON.stringify(res.data) == "{}");
                that.hasData = hasData;
                if (!hasData && res.code == 200) {
                    // a_m 人员权限管理  c_m  客户管理  e_i  企业信息  f_s  财务统计
                    // i_m  库存管理  i_m  订单管理  p_c  工作牌  p_l  产品库  p_m  人员管理
                    let { enterpriseName, ecardName, sex, ecardPhone, ecardNote, dcPermissionFlag, userId } = res.data,
                        { a_m, c_m, e_i, f_s, i_m, p_l, p_m, o_m } = res.data.userPermission,
                        baseInfo = [{
                            "title": "企业名称",
                            "value": enterpriseName
                        }, {
                            "title": "姓名",
                            "value": ecardName
                        }, {
                            "title": "性别",
                            "value": sex
                        }, {
                            "title": "手机号码",
                            "value": ecardPhone
                        }, {
                            "title": "所属部门",
                            "value": ecardNote
                        }],
                        powerInfo = [{
                            "title": "产品库",
                            "value": p_l == '' ? '无权查看' : (p_l == 'D' ? '不可见' : (p_l == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "客户管理",
                            "value": c_m == '' ? '无权查看' : (c_m == 'D' ? '不可见' : (c_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "企业信息",
                            "value": e_i == '' ? '无权查看' : (e_i == 'D' ? '不可见' : (e_i == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "订单管理",
                            "value": o_m == '' ? '无权查看' : (o_m == 'D' ? '不可见' : (o_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "人员管理",
                            "value": p_m == '' ? '无权查看' : (p_m == 'D' ? '不可见' : (p_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "财务统计",
                            "value": f_s == '' ? '无权查看' : (f_s == 'D' ? '不可见' : (f_s == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "库存管理",
                            "value": i_m == '' ? '无权查看' : (i_m == 'D' ? '不可见' : (i_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }, {
                            "title": "权限信息",
                            "value": a_m == '' ? '无权查看' : (a_m == 'D' ? '不可见' : (a_m == 'R' ? '仅可查看' : '查看/编辑'))
                        }];
                    /**
                     * target: 被修改的用户，目标用户
                        operator：操作者
                        修改员工信息：
                        (target.userId == operator.userId) || ( (operator.p_m : w) && (target.dcPermissionFlag == N) )
                        删除员工：
                        (target.userId != operator.userId) && ( (operator.p_m : w) && (target.dcPermissionFlag == N) )
                        查看权限：
                        (target.userId == operator.userId) || (operator.a_m : w) || (operator.a_m : r)
                        修改权限：
                         (operator.a_m : w) &&  (target.dcPermissionFlag == N)
                     */
                    //查看员工信息--是否显示修改按钮
                    if ((logerUserId == userId) || (logerEnterpriseStateInfo.userPermission.p_m == 'W' && dcPermissionFlag == 'N')) {
                        that.showModifyEnter = true;
                    }
                    // 查看员工信息--是否显示删除按钮
                    if ((logerUserId != userId) && (logerEnterpriseStateInfo.userPermission.p_m == 'W' && dcPermissionFlag == 'N')) {
                        this.showDeleteEnter = true;
                    }
                    // 查看员工信息--查看权限
                    if ((logerUserId == userId) || logerEnterpriseStateInfo.userPermission.a_m == 'W' || logerEnterpriseStateInfo.userPermission.a_m == 'R') {
                        that.showPowerList = true;
                    }
                    // 查看员工信息--是否显示修改权限按钮
                    if (logerEnterpriseStateInfo.userPermission.a_m == 'W' && dcPermissionFlag == 'N') {
                        that.showPowerList = true;
                        that.showPowerEnter = true;
                    }
                    that.WorkForm = {
                        name: ecardName,
                        sex: sex,
                        phone: ecardPhone,
                        department: ecardNote
                    }
                    that.drawerForm = res.data.userPermission
                    that.warkCardInfo = res.data
                    that.baseInfo = baseInfo
                    that.powerInfo = powerInfo
                }
            })
        },
        // 删除员工
        deleteBtn() {
            let that = this;
            that.$confirm('确定删除员工？', '提示').then(() => {
                that.$http.removeEnterpriseUser({
                    userId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    recordeId: parseInt(that.queryId),
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.$router.go(-1)
                })
            }).catch(() => { })
        },
        // 修改权限  企业用户设置权限接口
        async enterpriseUserRevisePermission() {
            let that = this;
            await that.$http.enterpriseUserRevisePermission({
                userId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                recordeId: parseInt(that.queryId),
                userPermission: that.drawerForm
            }).then(res => {
                if (res.code == 200) {
                    this.$common.message(res.msg, 'success')
                    this.drawer = false
                    this.enterpriseUserListDetails()
                } else if (res.code == 282) {
                    this.$common.message('修改成功', 'success')
                    this.$router.push({
                        name: 'SupplyIndex',
                    })
                }
            })
        },
        dialogClose() {
            this.dialogShow = false;
        }
    }
}
</script>
<style lang="less" scoped>
.work-card {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .infoBox {
            height: 140px;
            background: #F8F8F8;
            border-radius: 10px;
            padding: 48px 55px;

            .sexImg {
                position: absolute;
                right: 5px;
                bottom: 5px;
                z-index: 99;
            }

            .Name_Iphone {
                height: 80px;
                width: 230px;
                margin-left: 25px;
                border-right: 1px solid #999999;

                .name {
                    margin-top: 15px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #333333;
                    // border: 1px solid rebeccapurple;
                    width: 100%;
                    word-break: break-all;
                }

                .iphone {
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .firm {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin-left: 80px;
                width: 180px;
                // border: 1px solid red;
            }

            .section {
                width: 100px;
                font-size: 16px;
                font-weight: 400;
                color: #666666;
                margin-left: 30px;
                // border: 1px solid red;
            }

            .editBtn {
                width: 60px;
                margin-left: 50px;
                cursor: pointer;

                img {
                    margin-right: 5px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 400;
                    color: #2971FA;
                }


            }

            .deleteBtn {
                margin-top: 20px;
                width: 60px;
                margin-left: 50px;
                cursor: pointer;
                // border: 1px solid red;

                img {
                    margin-right: 5px;
                }

                .text {
                    font-size: 16px;
                    font-weight: 400;
                    color: #D93E14;
                }
            }
        }

        .jurisdiction {
            margin-top: 30px;
            padding: 30px 40px;
            background: #F8F8F8;
            border-radius: 10px;

            .text {
                font-size: 24px;
                font-weight: 400;
                color: #333333;
            }

            .btn {
                border-radius: 5px;
                border: 2px solid #1785F6;
                padding: 12px 15px;
                color: #016EF2;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;

                i {
                    margin-left: 10px;
                }
            }

            .list {
                margin-top: 30px;

                .item {
                    width: 50%;
                    border-bottom: 1px solid #666666;
                    padding: 20px 0;

                    .title {
                        width: 80px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin-right: 40px;
                    }

                    .value {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }

    // .backPreviousPage {
    //     align-self: flex-start;
    // }

    // .el-avatar {
    //     display: block;
    //     margin: 0 auto 20px;
    // }

    // .work-card-btns {
    //     margin-bottom: 20px;

    //     .modify:hover {
    //         cursor: pointer;
    //     }

    //     .delete {
    //         margin-left: 50px;
    //     }

    //     .delete:hover {
    //         cursor: pointer;
    //     }
    // }


    // .power-title {
    //     line-height: 40px;
    //     width: 80%;
    //     font-size: 18px;
    //     font-weight: bold;
    //     background: #a7a7a7;
    //     margin: 15px auto;
    //     padding-left: 20px;
    //     box-sizing: border-box;
    // }

    // .info {
    //     width: 70%;
    //     margin: auto;
    //     flex-wrap: wrap;
    //     border: 1px solid #999;

    //     .info-item:not(:last-child) {
    //         border-bottom: 1px solid #999;
    //     }

    //     .info-item {
    //         box-sizing: border-box;
    //         width: 100%;
    //         line-height: 40px;

    //         span:first-child {
    //             width: 25%;
    //             border-right: 1px solid #999;
    //             text-align: center;
    //         }

    //         span:last-child {
    //             text-align: left;
    //             padding-left: 20px;
    //         }
    //     }

    // }

    // .power-btn {
    //     border-radius: 20px;
    //     width: 20%;
    //     margin: 20px auto;
    // }

    .modify-ok {
        margin: auto;
        width: 160px;
        height: 60px;
    }

    .drawer-box {

        // width: 755px;
        // .el-radio-group {
        //     // padding-left: 10px
        // }
        /deep/ .el-drawer__header {
            padding: 20px 20px 0px 60px;
            color: #333333;
            font-size: 36px;
        }

        /deep/ .el-drawer__body {
            padding: 0 60px;
        }

        /deep/ .el-form-item__content {
            font-size: 20px;
        }

        /deep/ .el-radio {
            margin-top: 10px;
            margin-right: 100px;
            font-size: 16px;
        }

        // /deep/ .el-radio-button {
        //     margin-right: 100px;
        // }

        // /deep/ .el-radio-button .el-radio-button__inner {
        //     border: 1px solid #DCDFE6;
        //     border-radius: 5px;
        // }

        // /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
        //     box-shadow: 0 0 0 0 #409eff;
        // }

        /deep/ .el-radio-group {
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
        }

        .drawer-item {
            margin-bottom: 20px;

            span {
                display: block;
                font-weight: bold;
            }
        }

        .drawer-btns {
            margin: auto;
            width: 160px;
            margin-top: 40px;
            text-align: center;

            // .el-button {
            //     width: 50% !important;
            // }

            .el-button+.el-button {
                margin-left: 30px;
            }
        }
    }

    // .box-card {
    //     margin-top: 50px;
    //     width: 1000px;

    //     .el-collapse {
    //         border: none;
    //     }

    //     /deep/ .el-collapse-item__header {
    //         border: none;
    //         font-size: 16px;
    //         font-weight: 600;
    //     }

    //     .info-item {
    //         box-sizing: border-box;
    //         width: 100%;
    //         line-height: 40px;
    //         border: 1px solid #999;



    //         span:first-child {
    //             width: 25%;
    //             text-align: center;
    //             border-right: 1px solid #999;
    //         }

    //         span:last-child {
    //             text-align: left;
    //             padding-left: 20px;
    //         }
    //     }

    //     .info-item:not(:first-child) {
    //         border-top: none !important;
    //     }

    // }

    // /deep/ .el-card__body {
    //     padding: 10px 20px;

    //     .el-collapse-item__header {
    //         height: 30px;
    //         line-height: 30px;
    //     }

    //     .el-collapse-item__wrap {
    //         border: none !important;
    //     }

    //     .el-collapse-item__content {
    //         padding-top: 20px;
    //         padding-bottom: 0 !important;

    //         .power-btn {}
    //     }
    // }
}
</style>